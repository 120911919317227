import React, { useState, useEffect } from "react";
//import module
import Blazy from "blazy"
import "./SidebarRight.scss";
import Modal from 'react-modal';
import CreditInvitation from '../Modal/CreditInvitation/CreditInvitation';

declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let text = require('./Text.json');
let csrf;
const metaSelector = document.querySelector("meta[name='csrf-token']");

csrf = (metaSelector !== null) ? metaSelector.getAttribute("content") : "";
text = text[localize];

// clevertap
const triggerCleverTapRegister = async(link) => {
  try{
    await fetch(window.location.origin+'/api/v2/tracking-visit-page/register-page', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ entry_point: "before_booking" })
    }).then(() => window.location.href = link)
    .catch(()=> window.location.href = link);
  }catch(err){
    console.error(err)
  }
}

const SidebarRight = (props) => {
  const [giftPopUpIsOpen, setGiftPopUpIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showNotifier = (props.check_notifier['gender'] === null ||
      props.check_notifier['birthday'] === null ||
      props.check_notifier['verified'] === false) ||
    props.check_notifier['page_visited'] !== true
      
  useEffect(()=>{
    Modal.setAppElement('body');
  },[]) 

  const closeButton = () =>
  {
    document.getElementById("SidebarRight").className = "SidebarRight";
    document.getElementById("SidebarRightOverlay").className = "SidebarRight__Wrapper";
  }

  const openGiftModal = () => {
    setGiftPopUpIsOpen(true)
  }

  const closeGiftModal = () => {
    setGiftPopUpIsOpen(false)
  }

  const addInfo = async() => {
    await fetch(window.location.origin + '/' + localize + '/has_completed_user_info?user_id=' + props.user['id'], {
      method: "GET",
      headers: {
        'X-CSRF-Token': csrf,
        'Content-type': 'application/json'
      }
    }).then(response => {
      return response.json()
    }).then(response => {
      if (response['has_completed']) {
        openGiftModal();
      } else {
        window.location.replace(window.location.origin + '/' + localize + '/additional-information');
      }
    })
  }

  const openModal = () => {
    setIsModalOpen(true)
	}

  const closeModal = () => {
    setIsModalOpen(false)
	}

  const handler = (val) => {
    setIsModalOpen(val)
  }

  //call blazy function
  const bLazy = new Blazy({ });
  return (
    <>
      <div className="SidebarRight__Wrapper" id="SidebarRightOverlay"></div>
      <div id="SidebarRight" className="SidebarRight">
        { props.user ?
          <div>
            <div className="SidebarRight__Header">
              <div className="MenuProfile">
                <div className="MenuProfile__PhotoBox">
                  <img className="MenuProfile__Photo" src={ props.picture } alt="Default avatar"></img>
                </div>
                <div className="MenuProfile__Info">
                  <div className="MenuProfile__Name">{ props.first_name }</div>
                  <div className="MenuProfile__Title"> { props.role? text.photographer: text.escapist } </div>
                </div>
              </div>
              <label className="SidebarRight__CloseButton" onClick={ closeButton }>
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/svg/profile-drawer__btn-close.svg" alt="Profile drawer  btn close"></img>
              </label>
            </div>
            <div className="SidebarRight__Body">
              { !(props.userInfo) &&
                  <div className="popupWrapper">
                    <div className="popup" onClick={addInfo}>
                      <div className="popup__img">
                        <img className="popup__mainpic" src="https://cdn.sweetescape.com/misc/additional-information/ilu_credit-reward@1x.png" />
                      </div>
                      <div className="popup__content">
                        <p className={(localize === 'de' ? 'popup__content-title-de' : "popup__content-title")}>{text.add_info_1} ${props.creditValue} {text.add_info_2}</p>
                        <p className={(localize === 'de' ? "popup__content-subtitle-de" : "popup__content-subtitle")}>{text.add_info_subtitle}</p>
                      </div>
                      <div className="popup__close">
                        <img src="https://cdn.sweetescape.com/misc/svg/side-icon/chevron-right@1x.svg" />
                      </div>
                    </div>
                  </div>
              }
              <div className="SidebarRight__LoggedIn">
              { props.role &&
                <div>
                  <div className="SidebarRight__Divider"></div>
                  {
                    props.photographer_session.map((menu, index) => {
                      return (
                        <a className="SidebarRight__Item" key={index} href={ menu['table']['url'] }>
                          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={menu['table']['icon']} alt="Ico invite friends"></img>

                          <img className="Hover" src={menu['table']['icon_hover']} alt="Ico invite friends active"></img>
                          { menu['table']['text'] }
                        </a>
                      )
                    })
                  }
                </div>
              }
              { props.role &&
                <div>
                  <div className="SidebarRight__Divider"></div>
                  {
                    props.photographer_schedule.map( (menu, index) => {
                      return (
                        <a className="SidebarRight__Item" key={index} href={ menu['table']['url'] }>
                          <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={menu['table']['icon']} alt="Ico invite friends"></img>

                          <img className="Hover" src={menu['table']['icon_hover']} alt="Ico invite friends active"></img>
                          { menu['table']['text'] }
                        </a>
                      )
                    })
                  }
                </div>
              }
              <div className="SidebarRight__Divider"></div>
              <div className="SidebarRight__Item" onClick={openModal}>
                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="https://cdn.sweetescape.com/misc/svg/side-icon/ico-invite-friends.svg" alt="Ico invite friends"></img>
                <img className="Hover" src="https://cdn.sweetescape.com/misc/svg/side-icon/ico-invite-friends-active.svg" alt="Ico invite friends active"></img>
                { text.refer_friends }
              </div>
              { props.menu_setting &&
                <div>
                  {
                    props.menu_setting.map((menu, index) => {
                      if(menu['table']['index'] == 0)
                      {
                        return (
                            <a className="SidebarRight__Item" key={index} href={ menu['table']['url'] }>
                              <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={menu['table']['icon']} alt="Ico invite friends"></img>
                              <img className="Hover" src={menu['table']['icon_hover']} alt="Ico invite friends active"></img>
                              { menu['table']['text'] }
                              <span className="ServiceMyCredits">{ props.credit }</span>
                            </a>
                        )
                      }else if(menu['table']['index'] == 1)
                      {
                        return (
                          <a className="SidebarRight__Item" key={index} href={ menu['table']['url'] }>
                            <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={menu['table']['icon']} alt="Ico invite friends"></img>
                            <img className="Hover" src={menu['table']['icon_hover']} alt="Ico invite friends active"></img>
                            { menu['table']['text'] }
                          </a>
                        )
                      }else{
                        if (menu['table']['index'] == 2) {
                          return (
                            <div className="SidebarRight__Wrapper" key={index} >
                              {
                                (showNotifier) &&
                                <div className="SidebarRight__Notifier"/>
                              }
                              <a className="SidebarRight__Item" href={ menu['table']['url'] }>
                                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={menu['table']['icon']} alt="Ico invite friends"></img>
                                <img className="Hover" src={menu['table']['icon_hover']} alt="Ico invite friends active"></img>
                                { menu['table']['text'] }
                              </a>
                            </div>
                          )
                        }
                        else {
                          return (
                            <div key={index}>
                              <div className="SidebarRight__Divider"></div>
                              <a className="SidebarRight__Item" href={ menu['table']['url'] }>
                                <img className="b-lazy" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src={menu['table']['icon']} alt="Ico invite friends"></img>
                                <img className="Hover" src={menu['table']['icon_hover']} alt="Ico invite friends active"></img>
                                { menu['table']['text'] }
                              </a>
                            </div>
                          )
                        } 
                      }
                      
                    })
                  }
                </div>
              }
              </div>
            </div>
          </div>
          :
          <div>
            <div className="SidebarRight__Header">
              <div className="SidebarRight__Title">{ text.guest_escape }</div>
            </div>
            <div className="SidebarRight__BodyGuestLogin">
            <div className="SidebarRight__LoginFormTitle">{ text.guest_login }</div>
            <div className="Hr"></div>
            <div className="SidebarRight__LoginForm">
              <form action={`/${localize}/login`} acceptCharset="UTF-8" method="post">
                <input type="hidden" name="authenticity_token" value={csrf}></input>
                <input placeholder={text.email} className="Input" type="email" name="user[email]" id="user_email"></input>
                <input placeholder={text.password} className="Input" type="password" name="user[password]"></input>
                <input type="submit" name="commit" value={text.guest_login_button} className="Submit" data-disable-with="Login"></input>
              </form>
            </div>
            <div className="SidebarRight__LoginForm__Other">
              <div className="Oauth">
                <a className="Oauth__Link Oauth__LinkGoogle" href="/auth/google_oauth2?locale=en">
                  <img className="Oauth__Logo" src="https://cdn.sweetescape.com/misc/svg/google-oauth-logo.svg" alt="Google oauth logo"></img>
                  <div className="Oauth__Text">{ text.guest_login_google }</div>
                </a>
              </div>
              <div className="Oauth">
                <a className="Oauth__Link Oauth__LinkFacebook" href="/auth/facebook?locale=en">
                  <img className="Oauth__Logo" src="https://cdn.sweetescape.com/misc/svg/box-facebook.svg" alt="Box facebook"></img>
                  <div className="Oauth__Text">{ text.guest_login_fb }</div>
                </a>
                <a className="ForgotPassword" href="/en/reset">{ text.guest_forgot }</a>
                <div className="Hr"></div>
                  <div className="NotHaveAccount">{text.guest_register}<a href={`/${localize}/email_check`}>{ text.guest_sign_up }</a></div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      
      <Modal
        isOpen={giftPopUpIsOpen}
        onRequestClose={closeGiftModal}
        overlayClassName="Hero__OverlayModal"
        className="Hero__popupWrapper"
      >
        <div id="" className='Hero__popupModal'>
          <p className="Hero__popupModal-title">Your data has already been completed.</p>
          <p className="Hero__popupModal-subtitle">Looks like you've filled in this questionnaire before. Thank you for your time.</p>
          <button type="button" className="button" onClick={closeGiftModal}>OK</button>
        </div>
      </Modal>

      <Modal 
        htmlOpenClassName={'html' || null}
        isOpen={isModalOpen } 
        onRequestClose={closeModal}
        contentLabel="Credit Invitation"
        className="ReactModal__CreditInvitation"
        overlayClassName="CreditInvitation__OverlayModal"
      >
        <CreditInvitation credit_referral={props.credit_referral} referral_code={props.referral_code} recap={props.recap} handler = {handler} />
      </Modal>
    </>
  )
}
export default SidebarRight
