declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value");
let content = require("./Text.json");


import React from 'react';
import "./EmailCheck.scss";


type PropType = {
  google_oauth: string;
  facebook_oauth: string;
  login_url: string;
  email_validation_url: string;
  last_email: string;
  home_url: string;
  access_page: string;
  from_mokapos: boolean;
  merchant_email: string;
}

type StateType = {
  customClass: string;
  logoEmailCheck: string;
  emailDefaultValue: string;
}


class EmailCheck extends React.Component<PropType, StateType> {
  constructor(props){
    super(props);
    this.checkEmail = this.checkEmail.bind(this);
    content = content[localize]
    let instantShare = (this.props.access_page === 'instantshare') ? ' InstantShare' : ''
    let defaultValue = ""
    let emailCheckLogo = ""
    if (props.from_mokapos && props.merchant_email != "") {
      emailCheckLogo = "https://cdn.sweetescape.com/misc/png/fotto_logo.png"
      defaultValue = props.merchant_email
    }
    else if (this.props.access_page === 'instantshare') {
      emailCheckLogo = 'https://cdn.sweetescape.com/misc/png/logo-instant-share.png'
    }
    else {
      emailCheckLogo = 'https://cdn.sweetescape.com/misc/svg/logo.svg'
    }
    
    this.state = {customClass: instantShare, logoEmailCheck: emailCheckLogo, emailDefaultValue: defaultValue} 
  }

  checkEmail(form) {
    form.preventDefault();
    if(this.checkFormat()) {
      if(this.props.last_email != (document.querySelector('.EmailCheck__EmailInput') as HTMLInputElement).value) {
        document.cookie = "otpCounter=; expires=Thu, 18 Dec 2013 12:00:00 UTC";
      }
      (document.querySelector('.EmailCheck__Validation') as HTMLFormElement).submit();
    }
    else {
      var email = (document.querySelector('.EmailCheck__EmailInput') as HTMLInputElement).value;
      document.querySelector('.EmailCheck__ErrorMessage').innerHTML = (email === "") ? content.errorNoEmpty : content.errorEmailFormat; 
      if (!(document.querySelector('.EmailCheck__EmailInput').classList.contains('error'))) { 
        (document.querySelector('.EmailCheck__EmailInput') as HTMLInputElement).classList.add('error')
      }
    }
  }
  
  componentDidMount() {
    document.cookie = "dontResetOtp=; expires=Thu, 18 Dec 2013 12:00:00 UTC";
    document.querySelector('.EmailCheck__EmailInput').addEventListener('input', this.checkFormat);
  }
  
  checkFormat = () => {
    var validate = /\S+@\S+\.\S+/;
    var email = (document.querySelector('.EmailCheck__EmailInput') as HTMLInputElement).value;
    if(validate.test(email)) {
      document.querySelector('.EmailCheck__ErrorMessage').innerHTML = ""; 
      (document.querySelector('.EmailCheck__EmailInput') as HTMLInputElement).classList.remove('error')
      return true;
    }
    else{
     return false;
    }

  }
  render() {
    return(
      <div className="EmailCheck">
        <div className="container EmailCheck__Box">
           
           {this.props.access_page &&
              <div className={"EmailCheck__Head" + this.state.customClass}>
                { this.props.from_mokapos ?
                    <img src={this.state.logoEmailCheck} />
                  :
                    <a href={this.props.home_url} > 
                      <img src={this.state.logoEmailCheck} />
                    </a>
                }
              </div>
            }
          <div className="EmailCheck__Body">
            <div className="EmailCheck__Title">
              {this.props.access_page === 'instantshare' ? content.instant_share_title : this.props.from_mokapos ? "Welcome." : content.title }
            </div>
            
            <div className="EmailCheck__Subtitle">
              {this.props.access_page === 'instantshare' ? content.instant_share_subtitle : content.subtitle}
            </div>
            
            <div className="EmailCheck__Form">
              <form onSubmit={this.checkEmail} action= {this.props.email_validation_url} className= "EmailCheck__Validation" autoComplete = "off">
                <div className="EmailCheck__Wrapper">
                  <input type="text"
                        name="email"
                        id="user_email"
                        defaultValue={this.state.emailDefaultValue}
                        placeholder={content.placeholder}
                        className="EmailCheck__EmailInput" />
                  <div className="EmailCheck__ErrorMessage" />
                </div>
                <input type="submit" value={content.buttonLabel} className="EmailCheck__Button" /> 
              </form>
            </div>
            
            { this.props.from_mokapos === false ?
                <>
                  <div className="EmailCheck__Separator">
                    <div className="EmailCheck__SeparatorLineLeft" />
                    <div className="EmailCheck__SeparatorWord">{content.registerWith}</div>
                    <div className="EmailCheck__SeparatorLineRight" />
                  </div>
                  
                  <div className="EmailCheck__Oauth">
                    <a href={this.props.google_oauth} >
                      <div className="EmailCheck__OauthGoogle" >
                        <img src="https://cdn.sweetescape.com/misc/svg/google-oauth-logo.svg" />
                        <span>Google</span>
                      </div>
                    </a>
                    
                    <a href={this.props.facebook_oauth} >
                      <div className="EmailCheck__OauthFacebook" >
                        <img src="https://cdn.sweetescape.com/misc/svg/box-facebook.svg" />
                        <span>Facebook</span>
                      </div>
                    </a>
                  </div>
                </>
              :
                null
            }
            
            {this.props.access_page !== 'instantshare' ? 
              <div className="EmailCheck__Footer" >
                <span>{content.haveAccount} </span>
                <a className="EmailCheck__RedirectLogin" href={this.props.login_url} >
                  {content.loginHere}
                </a>
              </div>
              : null
            } 
          </div>
        </div>
      </div>
    );
	}
}

export default EmailCheck
